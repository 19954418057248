<template>
  <section class="invoice-preview-wrapper">
    <!-- <content-loader
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle
        cx="29"
        cy="30"
        r="17"
      />
      <rect
        x="58"
        y="18"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="58"
        y="34"
        rx="2"
        ry="2"
        width="140"
        height="10"
      />
      <rect
        x="-7"
        y="60"
        rx="2"
        ry="2"
        width="408"
        height="132"
      />
    </content-loader> -->
    <!-- v-if="!isFetchingFormDetails && validOutput" -->
    <div class="position-relative">
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <b-card class="m-0 mb-1">
          <div class="d-flex justify-content-between">
            <h2 class="my-auto">
              Fiches remplies
            </h2>
            <!-- <b-button
              v-if="$can('read','Public')"
              class="mt-1 float-right my-auto"
              variant="primary"
              @click="createFormulaire"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Nouvelle fiche</span>
            </b-button> -->
          </div>

          <hr class="my-2">
          <!-- <b-row>
            <b-col lg="4">
              <div class="d-flex flex-column col-6 nopadding">
                <div class="text-center">
                  <strong>Rechercher avec CodeQR</strong>
                </div>
                <br>
                <div class="my-auto border-primary">
                  <qrcode-drop-zone
                    @decode="onDecode"
                    @dragover="onDragOver"
                  >
                    <div
                      v-if="!loadQR"
                      style="height: 70px"
                      class="d-flex justify-content-center"
                      :class="{
                        'border-success bg-success': draggingOver,
                      }"
                    >
                      <div class="my-auto mx-auto text-center">
                        <strong
                          v-if="!loadQR"
                        >Glisser et déposer <br>
                          CodeQR</strong>
                        <strong
                          v-else
                          class="border-success bg-success"
                        >CodeQR Chargé</strong>
                      </div>
                    </div>
                    <div
                      v-else
                      style="height: 70px"
                      class="d-flex justify-content-center bg-success"
                    >
                      <div class="my-auto">
                        <strong class="border-success">CodeQR Chargé</strong>
                      </div>
                    </div>
                  </qrcode-drop-zone>
                </div>
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    class="col-12"
                    @click="useCamera = !useCamera"
                  >
                    Scanner
                  </b-button>
                </div>
                <div
                  v-if="useCamera"
                  class="mx-1 my-auto border-primary"
                  style="height: 125px; width 50px;"
                >
                  <qrcode-stream @decode="onDecode" />
                </div>
              </div>
            </b-col>
            <b-col lg="8">
              <div class="d-flex flex-row-reverse nopadding">
                <b-form-group
                  class="col pr-0 pl-0"
                  label-cols-xl="3"
                  label="Période"
                >
                  <b-input-group>
                    <DateRangeWidget
                      v-model="periode"
                      :range="periode"
                    />
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  class="col"
                  label-cols-xl="3"
                  label="Référence"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="reference"
                      type="text"
                      class="form-control"
                      placeholder=""
                    />
                    <b-input-group-append
                      is-text
                      @click="
                        () => {
                          reference = null;
                        }
                      "
                    >
                      <feather-icon
                        v-b-tooltip.hover.bottom="'Vider le champs!'"
                        icon="XCircleIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="d-flex flex-row-reverse">
                <b-button
                  class="float-right"
                  variant="primary"
                  @click="search()"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-25"
                  />
                  <span>Rechercher</span>
                </b-button>
              </div>
            </b-col>
          </b-row> -->

          <div class="my-1">
            <b-table
              ref="refFormsTable"
              :items="tableData"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Aucune donnée trouvée"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle" />
                </div>
              </template>
              <template #cell(createdAt)="data">
                {{ convertDateToLocalString(data.item.createdAt) }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  variant="primary"
                  class="mx-0"
                  @click="
                    $router.push({
                      name: 'formulaires-details',
                      params: {
                        id: data.item._id,
                        model: data.item.apiRoutePrefixe,
                      },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" />
                  <span class="text-nowrap"> Détails</span>
                </b-button>
              </template>
            </b-table>

            <div
              v-if="tableData && tableData.length > 1"
              class="d-flex justify-content-between mt-1 flex-wrap gap-10"
            >
              <div class="d-flex flex-row">
                <span class="my-auto mr-1">Afficher </span>
                <div>
                  <b-form-select
                    v-model="paginationData.perPage"
                    :options="optionsPerPage"
                    size="sm"
                    class="form-control col"
                  />
                </div>
                <span class="my-auto ml-1">lignes </span>
              </div>

              <div>
                <b-pagination
                  v-model="paginationData.currentPage"
                  :total-rows="paginationData.total"
                  :per-page="paginationData.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>

              <div class="my-auto">
                <span
                  class="my-auto"
                >Affichage de {{ paginationData.metaData.from }} à
                  {{ paginationData.metaData.to }} sur
                  {{ paginationData.total }}
                </span>
              </div>
            </div>
          </div>
        </b-card>
      </b-card>
    </div>

    <div class="main-grid">
      <!-- content -->
      <!-- <div class="position-relative">
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-card class="m-0 mb-1">
            <div class="d-flex justify-content-between">
              <h2 class="my-auto">
                {{ formTitle }}
              </h2>
              <b-button
                v-if="$can('read','Public')"
                class="mt-1 float-right my-auto"
                variant="primary"
                @click="createFormulaire"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Nouvelle fiche</span>
              </b-button>
            </div>

            <hr class="my-2">
            <b-row>
              <b-col lg="4">
                <div class="d-flex flex-column col-6 nopadding">
                  <div class="text-center">
                    <strong>Rechercher avec CodeQR</strong>
                  </div>
                  <br>
                  <div class="my-auto border-primary">
                    <qrcode-drop-zone
                      @decode="onDecode"
                      @dragover="onDragOver"
                    >
                      <div
                        v-if="!loadQR"
                        style="height: 70px"
                        class="d-flex justify-content-center"
                        :class="{
                          'border-success bg-success': draggingOver,
                        }"
                      >
                        <div class="my-auto mx-auto text-center">
                          <strong
                            v-if="!loadQR"
                          >Glisser et déposer <br>
                            CodeQR</strong>
                          <strong
                            v-else
                            class="border-success bg-success"
                          >CodeQR Chargé</strong>
                        </div>
                      </div>
                      <div
                        v-else
                        style="height: 70px"
                        class="d-flex justify-content-center bg-success"
                      >
                        <div class="my-auto">
                          <strong class="border-success">CodeQR Chargé</strong>
                        </div>
                      </div>
                    </qrcode-drop-zone>
                  </div>
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      class="col-12"
                      @click="useCamera = !useCamera"
                    >
                      Scanner
                    </b-button>
                  </div>
                  <div
                    v-if="useCamera"
                    class="mx-1 my-auto border-primary"
                    style="height: 125px; width 50px;"
                  >
                    <qrcode-stream @decode="onDecode" />
                  </div>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="d-flex flex-row-reverse nopadding">
                  <b-form-group
                    class="col pr-0 pl-0"
                    label-cols-xl="3"
                    label="Période"
                  >
                    <b-input-group>
                      <DateRangeWidget
                        v-model="periode"
                        :range="periode"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    class="col"
                    label-cols-xl="3"
                    label="Référence"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="reference"
                        type="text"
                        class="form-control"
                        placeholder=""
                      />
                      <b-input-group-append
                        is-text
                        @click="
                          () => {
                            reference = null;
                          }
                        "
                      >
                        <feather-icon
                          v-b-tooltip.hover.bottom="'Vider le champs!'"
                          icon="XCircleIcon"
                          class="cursor-pointer"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="d-flex flex-row-reverse">
                  <b-button
                    class="float-right"
                    variant="primary"
                    @click="search()"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="mr-25"
                    />
                    <span>Rechercher</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <div class="my-1">
              <b-table
                ref="refFormsTable"
                :items="tableData"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="Aucune donnée trouvée"
                :busy="isLoading"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" />
                  </div>
                </template>
                <template #cell(createdAt)="data">
                  {{ convertDateToLocalString(data.item.createdAt) }}
                </template>
                <template #cell(actions)="data">
                  <b-button
                    variant="primary"
                    class="mx-0"
                    @click="
                      $router.push({
                        name: 'formulaires-details',
                        params: {
                          id: data.item._id,
                          model: selectedForm.apiRoutePrefixe,
                        },
                      })
                    "
                  >
                    <feather-icon icon="EyeIcon" />
                    <span class="text-nowrap">Détails</span>
                  </b-button>
                </template>
              </b-table>

              <div
                v-if="tableData && tableData.length > 1"
                class="d-flex justify-content-between mt-1 flex-wrap gap-10"
              >
                <div class="d-flex flex-row">
                  <span class="my-auto mr-1">Afficher </span>
                  <div>
                    <b-form-select
                      v-model="paginationData.perPage"
                      :options="optionsPerPage"
                      size="sm"
                      class="form-control col"
                    />
                  </div>
                  <span class="my-auto ml-1">lignes </span>
                </div>

                <div>
                  <b-pagination
                    v-model="paginationData.currentPage"
                    :total-rows="paginationData.total"
                    :per-page="paginationData.perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>

                <div class="my-auto">
                  <span
                    class="my-auto"
                  >Affichage de {{ paginationData.metaData.from }} à
                    {{ paginationData.metaData.to }} sur
                    {{ paginationData.total }}
                  </span>
                </div>
              </div>
            </div>
          </b-card>
        </b-card>
      </div> -->

      <!-- sidebar -->
      <!-- <div class="options-sidebar card">
        <div class="sidecard">
          <b-card-header
            class="
              align-items-baseline
              d-flex
              justify-content-between
              py-1
              bg-white
            "
          >
            <div>
              <b-card-title class="mb-0">
                Dénombrement <br>
                <small> {{ selectedStats.label }} </small> <br>
                <small>Total:
                  <span class="text-primary">
                    <strong>
                      {{
                        (series[0].data[0] || 0) +
                          (series[0].data[1] || 0) +
                          (series[0].data[2] || 0)
                      }}
                      fiches</strong>
                  </span></small>
              </b-card-title>

              <b-card-text class="mb-0">
              </b-card-text>
            </div>
            <b-dropdown
              v-model="selectedStats"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              text="Période"
              variant="outline-secondary"
              right
            >
              <b-dropdown-item
                v-for="option in optionsStats"
                :key="option.id"
                @click="selectOptionStat(option)"
              >
                {{ option.label }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body class="pb-0">
            <div
              v-if="loading"
              class="text-center mt-2"
            >
              <div
                class="spinner-border text-primary"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else>
              <vue-apex-charts
                type="bar"
                height="280"
                :options="chartOptions"
                :series="series"
              />
            </div>
          </b-card-body>
        </div>
      </div> -->
    </div>
    <!-- <invoice-sidebar-send-invoice
      :form-data="validOutput"
      :pdf="pdf"
    /> -->
    <!-- <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import utilsService from '@/services/utils/utils.service'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import formulaireStoreModule from '@/store/formulaire'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

const mockData = {
  formstatagences: [
    {
      _id: '62986fa9b2fea220411a6ef3',
      annee: '2019',
      mois: 'Février',
      departement: 'ATACORA',
      commune: 'KEROU',
      arrondissement: 'KAOBAGOU',
      quartier: 'cdcdf',
      nomEtablissement: 'vdvb',
      reverseAdministration: '',
      numIFU: 'bvdfvbh',
      nomPromoteur: 'vdfvdnjb',
      nationalitePromoteur: 'Togo',
      adresseComplete: 'vdsv',
      telephone1: 'vdvf',
      telephone2: 'vdvd',
      typeEtablissement: 'Tour Operator',
      agrementMinistere: 'oui',
      referenceAgrement: 'vdfvd',
      employesSalaries: {
        hommeTempspleinNationaux: 1,
        hommeTempspartielNationaux: 1,
        hommeTempspleinEtrangers: 1,
        hommeTempspartielEtrangers: 1,
        femmeTempspleinNationaux: 1,
        femmeTempspartielNationaux: 1,
        femmeTempspleinEtrangers: 1,
        femmeTempspartielEtrangers: 1,
      },
      nouveauEmplois: {
        hommes: 1,
        femmes: 1,
      },
      employesNonSalaries: {
        hommeTempspleinNationaux: 1,
        hommeTempspartielNationaux: 1,
        hommeTempspleinEtrangers: 1,
        hommeTempspartielEtrangers: 1,
        femmeTempspleinNationaux: 1,
        femmeTempspartielNationaux: 1,
        femmeTempspleinEtrangers: 1,
        femmeTempspartielEtrangers: 1,
      },
      voyagesInternes: {
        sansFrais: 1,
        avecFrais: 1,
      },
      voyagesInternationaux: {
        entreeSansFrais: 1,
        entreeAvecFrais: 1,
        sortieSansFrais: 1,
        sortieAvecFrais: 1,
      },
      recettesBrutes: {
        billetterie: 1,
        voyagesOrganises: 1,
        croisieresToutCompris: 1,
        autresProduits: 1,
      },
      commissions: {
        billetterie: 1,
        voyagesOrganises: 1,
        croisieresToutCompris: 1,
        hebergement: 1,
        locationAutomobile: 1,
        primesAssurance: 1,
        autresCommissions: 1,
      },
      chargesSalariales: 1,
      autresDepense: 1,
      createdAt: '2022-06-02T08:07:05.159Z',
      updatedAt: '2022-06-02T08:07:05.159Z',
      __v: 0,
      reference: 'fg5§gH4',
    },
    {
      _id: '6298f32d88dbef1f3501d713',
      annee: '2019',
      mois: 'Janvier',
      departement: 'ATACORA',
      commune: 'COBLY',
      arrondissement: 'GOUMORI',
      quartier: 'bhjbhjb',
      nomEtablissement: 'hjkdbvvfdfbjkdkjbd',
      reverseAdministration: '',
      numIFU: 'vbdb',
      nomPromoteur: 'bdbvdvdfvbjdfbvdfjv',
      nationalitePromoteur: 'Togo',
      adresseComplete: 'vdfvdb',
      telephone1: 'vdvbdkfj',
      typeEtablissement: 'Agence de voyage',
      agrementMinistere: 'oui',
      referenceAgrement: 'fvdvbdjkfbvj',
      employesSalaries: {
        hommeTempspleinNationaux: 1,
        hommeTempspartielNationaux: 1,
        hommeTempspleinEtrangers: 1,
        hommeTempspartielEtrangers: 1,
        femmeTempspleinNationaux: 1,
        femmeTempspartielNationaux: 1,
        femmeTempspleinEtrangers: 1,
        femmeTempspartielEtrangers: 1,
      },
      nouveauEmplois: {
        hommes: 1,
        femmes: 1,
      },
      employesNonSalaries: {
        hommeTempspleinNationaux: 1,
        hommeTempspartielNationaux: 1,
        hommeTempspleinEtrangers: 1,
        hommeTempspartielEtrangers: 1,
        femmeTempspleinNationaux: 1,
        femmeTempspartielNationaux: 1,
        femmeTempspleinEtrangers: 1,
        femmeTempspartielEtrangers: 1,
      },
      voyagesInternes: {
        sansFrais: 1,
        avecFrais: 1,
      },
      voyagesInternationaux: {
        entreeSansFrais: 1,
        entreeAvecFrais: 1,
        sortieSansFrais: 1,
        sortieAvecFrais: 1,
      },
      recettesBrutes: {
        billetterie: 1,
        voyagesOrganises: 1,
        croisieresToutCompris: 1,
        autresProduits: 1,
      },
      commissions: {
        billetterie: 1,
        voyagesOrganises: 1,
        croisieresToutCompris: 1,
        hebergement: 1,
        locationAutomobile: 1,
        primesAssurance: 1,
        autresCommissions: 1,
      },
      chargesSalariales: 1,
      autresDepense: 1,
      createdAt: '2022-06-02T17:28:13.912Z',
      updatedAt: '2022-06-02T17:28:13.912Z',
      reference: 'eDEzeXZ',
      __v: 0,
    },
    {
      _id: '6299c6bd16f0d765820ab87f',
      annee: '2020',
      mois: 'Janvier',
      departement: 'ALIBORI',
      commune: 'BANIKOARA',
      arrondissement: 'GOMPAROU',
      quartier: 'jhnjkvnfb',
      nomEtablissement: 'vdfnjn',
      reverseAdministration: '',
      numIFU: 'bdfjbdkj',
      nomPromoteur: 'bd fnjkbn',
      nationalitePromoteur: 'Bénin',
      adresseComplete: 'vdfnvjkdb',
      telephone1: 'njkvdfbjv',
      typeEtablissement: 'Agence de voyage',
      agrementMinistere: 'oui',
      referenceAgrement: 'ndsjvdjs',
      employesSalaries: {
        hommeTempspleinNationaux: 1,
        hommeTempspartielNationaux: 1,
        hommeTempspleinEtrangers: 1,
        hommeTempspartielEtrangers: 1,
        femmeTempspleinNationaux: 1,
        femmeTempspartielNationaux: 1,
        femmeTempspleinEtrangers: 1,
        femmeTempspartielEtrangers: 1,
      },
      nouveauEmplois: {
        hommes: 1,
        femmes: 1,
      },
      employesNonSalaries: {
        hommeTempspleinNationaux: 1,
        hommeTempspartielNationaux: 1,
        hommeTempspleinEtrangers: 1,
        hommeTempspartielEtrangers: 1,
        femmeTempspleinNationaux: 1,
        femmeTempspartielNationaux: 1,
        femmeTempspleinEtrangers: 1,
        femmeTempspartielEtrangers: 1,
      },
      voyagesInternes: {
        sansFrais: 1,
        avecFrais: 1,
      },
      voyagesInternationaux: {
        entreeSansFrais: 1,
        entreeAvecFrais: 1,
        sortieSansFrais: 1,
        sortieAvecFrais: 1,
      },
      recettesBrutes: {
        billetterie: 1,
        voyagesOrganises: 1,
        croisieresToutCompris: 1,
        autresProduits: 1,
      },
      commissions: {
        billetterie: 1,
        voyagesOrganises: 1,
        croisieresToutCompris: 1,
        hebergement: 1,
        locationAutomobile: 1,
        primesAssurance: 1,
        autresCommissions: 1,
      },
      chargesSalariales: 1,
      autresDepense: 1,
      createdAt: '2022-06-03T08:30:53.239Z',
      updatedAt: '2022-06-03T08:30:53.239Z',
      reference: 'e8bEm7Y',
      __v: 0,
    },
  ],
  total: 3,
}

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'formulaire', module: formulaireStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: ['a', 'b', 'b'],
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: true,
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            columnWidth: '17%',
            endingShape: 'rounded',
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
      },
      series: [
        {
          name: 'Fiches',
          data: [95, 177, 84],
        },
      ],
      // chartData: {
      //   "years": [
      //     "2020",
      //     "2019",
      //     "2018"
      //   ],
      //   "price": "25,852",
      //   "budget": "56,800",
      //   "revenueReport": {
      //     "series": [
      //       {
      //         "name": "Earning",
      //         "data": [
      //           95,
      //           177,
      //           284,
      //           256,
      //           105,
      //           63,
      //           168,
      //           218,
      //           72
      //         ]
      //       },
      //       {
      //         "name": "Expense",
      //         "data": [
      //           -145,
      //           -80,
      //           -60,
      //           -180,
      //           -100,
      //           -60,
      //           -85,
      //           -75,
      //           -100
      //         ]
      //       }
      //     ]
      //   },
      //   "budgetChart": {
      //     "series": [
      //       {
      //         "data": [
      //           61,
      //           48,
      //           69,
      //           52,
      //           60,
      //           40,
      //           79,
      //           60,
      //           59,
      //           43,
      //           62
      //         ]
      //       },
      //       {
      //         "data": [
      //           20,
      //           10,
      //           30,
      //           15,
      //           23,
      //           0,
      //           25,
      //           15,
      //           20,
      //           5,
      //           27
      //         ]
      //       }
      //     ]
      //   }
      // },

      loading: false,
      statsLoad: null,

      lastestRecordedForm: null,
      periode: null,
      reference: null,
      curPage: 1,
      perPage: 20,
      query: {
        options: {},
        filter: {
          // text: [],
          // type: [],
        },
        // page: this.curPage - 1,
        // perPage: this.perPage
      },

      filters: {
        periodeDu: null,
        periodeAu: null,
      },
      tableColumns: [
        { key: 'reference', label: 'Ref', sortable: true },
        // { key: 'nomEtablissement', label: 'Nom', sortable: true },
        { key: 'createdAt', label: 'Date', sortable: true },
        { key: 'actions' },
      ],
      tableData: [],

      filteredTableData: [],
      // formTitle: this.$route.params.title || '',
      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 15,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      isLoading: false,
      draggingOver: false,
      model: '',
      useCamera: false,
      loadQR: false,

      optionsStats: [
        { id: 1, label: '3 derniers trimestres', value: '3t' },
        { id: 2, label: '3 derniers semestres', value: '3s' },
        { id: 3, label: '3 dernières années', value: '3a' },
      ],
      selectedStats: { id: 3, label: '3 dernières années', value: '3a' },
    }
  },

  computed: {},

  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // console.log('val1: ', val)
        this.getListFormulairesGrandPublic()
        // this.selectOptionStat(this.selectedStats)
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListFormulairesGrandPublic()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListFormulairesGrandPublic()
      },
    },
    filters: {
      // immediate: true,
      deep: true,
      handler(val, old) {
        // console.log('val: ', val)
        // console.log('val :', val)
        // this.search()
        this.filteredTableData = this.tableData.filter(item => {
          const condition = true
          // console.log(condition, item)
          return condition
        })
      },
    },
    tableData: {
      immediate: true,
      deep: true,
      handler(val, old) {
        // console.log('val :', val)
        // this.search()
        this.filteredTableData = this.tableData.filter(item => {
          const condition = true
          // console.log('condition: ', condition, item)
          return condition
        })
        if (val && Array.isArray(val) && val.length > 0) {
          this.lastestRecordedForm = val[val.length - 1]
        }
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  mounted() {
    // var myDate = "26-06-2022";
    // myDate = myDate.split("-");
    // var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    // console.log("the date : ", myDate, newDate.getTime());
    // this.getListFormulairesGrandPublic()
    // console.log(this.tableData)
  },

  methods: {
    ...mapActions('formulaire', {
      fetchFormulaires: 'fetchFormulaires',
      action_getFormulairesGrandPublic: 'getFormulairesGrandPublic',
    }),
    // createFormulaire() {
    //   console.log(this.$store.state.auth.user)
    //   const { params } = this.selectedForm.createRouteProps
    //   this.$router.push({
    //     ...this.selectedForm.createRouteProps,
    //     params: {
    //       ...params,
    //     },
    //   })
    // },
    async getListFormulairesGrandPublic() {
      this.tableData = []
      this.isLoading = true
      const { currentUserId } = utilsService.currentUserUtils()
      // console.log(`model: ${this.model}`, 'apiRoutejajaa', apiRoutePrefixeWithoutDash)
      const idUser = { id: currentUserId }
      // console.log('idUser: ', idUser['id']);
      await this.action_getFormulairesGrandPublic(idUser.id)
        .then(res => {
          // console.log('res: ', res);
          this.tableData = res.data.resources
          // console.log(' -------- tableData -------------', this.tableData)
          // this.paginationData.total = res.data.total || res.data[apiRoutePrefixeWithoutDash].length || 0
          // this.useMockData()
          this.isLoading = false

          if (this.paginationData.metaData.from === 0) { this.paginationData.metaData.from += 1 } else {
            this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
          }
          this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
            - this.paginationData.perPage
            + this.tableData.length
        })
        .catch(err => {
          console.log('err', err)
          // this.useMockData()

          this.isLoading = false
        })
    },
    useMockData() {
      const data = mockData
      // this.tableData = data.formstatagences
      this.paginationData.total = mockData.total
    },
    onDecode(decodedString) {
      if (decodedString) {
        this.loadQR = true
        const params = decodedString.split('|')
        // console.log(params)
        // console.log(`model: ${this.model}`)
        this.$router.push({
          name: 'formulaires-details',
          params: { id: params[0], model: this.selectedForm.apiRoutePrefixe },
        })
      }
    },
    onDragOver(draggingOver) {
      this.draggingOver = draggingOver
    },
    search() {
      if (this.periode) {
        // console.log(this.periode)
        const { from, to } = this.periode
        // console.log('from', from)
        // console.log('to', to)
        if (!this.query.filter.text) {
          this.query.filter = {
            text: [],
            type: [],
          }
        }
        if (to) {
          this.query.filter.text.push(`${from},${to}`)
        } else {
          this.query.filter.text.push(`${from},${from}`)
        }
        this.query.filter.type.push('createdAt')

        // console.log('search this.query: ', this.query)
      } else {
        this.query.filter = {}
      }
      if (this.reference && this.reference != '') {
        const object = { reference: this.reference }
        this.query.options = { ...object }
        // console.log(this.query)
      } else {
        this.query.options = {}
      }

      this.getListFormulairesGrandPublic({ ...this.query })
    },
    async selectOptionStat(item) {
      this.loading = true
      // console.log(item.id,item.label,item.value);
      this.selectedStats = item
      const currentDate = moment()
      const lastItteration = []
      this.series[0].data = []

      if (item.value == '3a') {
        lastItteration.push({
          born1: moment().add(-1, 'M').endOf('month'),
          born2: moment().add(-12, 'M').startOf('month'),
        })
        lastItteration.push({
          born1: moment().add(-13, 'M').endOf('month'),
          born2: moment().add(-24, 'M').startOf('month'),
        })
        lastItteration.push({
          born1: moment().add(-25, 'M').endOf('month'),
          born2: moment().add(-36, 'M').startOf('month'),
        })
        this.chartOptions.xaxis.categories = [
          `${lastItteration[0].born1.format('D/M/YYYY')
          } au ${
            lastItteration[0].born2.format('D/M/YYYY')}`,
          `${lastItteration[1].born1.format('D/M/YYYY')
          } au ${
            lastItteration[1].born2.format('D/M/YYYY')}`,
          `${lastItteration[2].born1.format('D/M/YYYY')
          } au ${
            lastItteration[2].born2.format('D/M/YYYY')}`,
        ]
      }
      if (item.value == '3s') {
        lastItteration.push({
          born1: moment().add(-1, 'M').endOf('month'),
          born2: moment().add(-6, 'M').startOf('month'),
        })
        lastItteration.push({
          born1: moment().add(-7, 'M').endOf('month'),
          born2: moment().add(-12, 'M').startOf('month'),
        })
        lastItteration.push({
          born1: moment().add(-13, 'M').endOf('month'),
          born2: moment().add(-18, 'M').startOf('month'),
        })
        this.chartOptions.xaxis.categories = [
          `${lastItteration[0].born1.format('D/M/YYYY')
          } au ${
            lastItteration[0].born2.format('D/M/YYYY')}`,
          `${lastItteration[1].born1.format('D/M/YYYY')
          } au ${
            lastItteration[1].born2.format('D/M/YYYY')}`,
          `${lastItteration[2].born1.format('D/M/YYYY')
          } au ${
            lastItteration[2].born2.format('D/M/YYYY')}`,
        ]
      }
      if (item.value == '3t') {
        lastItteration.push({
          born1: moment().add(-1, 'M').endOf('month'),
          born2: moment().add(-3, 'M').startOf('month'),
        })
        lastItteration.push({
          born1: moment().add(-4, 'M').endOf('month'),
          born2: moment().add(-6, 'M').startOf('month'),
        })
        lastItteration.push({
          born1: moment().add(-7, 'M').endOf('month'),
          born2: moment().add(-9, 'M').startOf('month'),
        })
        this.chartOptions.xaxis.categories = [
          `${lastItteration[0].born1.format('D/M/YYYY')
          } au ${
            lastItteration[0].born2.format('D/M/YYYY')}`,
          `${lastItteration[1].born1.format('D/M/YYYY')
          } au ${
            lastItteration[1].born2.format('D/M/YYYY')}`,
          `${lastItteration[2].born1.format('D/M/YYYY')
          } au ${
            lastItteration[2].born2.format('D/M/YYYY')}`,
        ]
      }

      this.statsLoad = null
      await this.fetchDatasForStats(0, 1000000000)
      // console.log('------------------------------ this.statsLoad: ', this.statsLoad)
      // filter results by dates

      for (let i = 0; i < lastItteration.length; i++) {
        let count = 0
        this.statsLoad.filter(item => {
          const momentDate = moment(item.createdAt)
          if (momentDate.isBetween(lastItteration[i].born2, lastItteration[i].born1)) count += 1
        })
        // console.log('currentCount: ', count)
        this.series[0].data.push(count)
        // console.log('-----------------------------------: end ')
      }

      this.loading = false
      // setTimeout(() => {this.loading = false}, 100);
      // console.log('lastItteration: ', lastItteration);
    },
    async fetchDatasForStats(page, perPage) {
      if (!this.selectedForm) return
      const { apiRoutePrefixe } = this.selectedForm
      // const apiRoutePrefixeWithoutDash = apiRoutePrefixe.replace('-', '')
      if (!apiRoutePrefixe) return
      await this.action_getFormulaireGrandPublic({
        formType: apiRoutePrefixe,
        params: { page, perPage } || null,
      })
        .then(res => {
          // console.log('fetchDatasForStats response: ', res.data.formstatagences)
          this.statsLoad = res.data.resources
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";
.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;
  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}
// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;
    .options-sidebar {
      overflow: auto;
      height: unset;
      // padding-bottom: 59px;
      position: unset;
      // right: 1.5rem;
      width: unset;
    }
  }
}
.d-content {
  display: contents;
}
#sidebar-wrapper {
  //z-index: 1000;
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  // margin-bottom: 100%;
  overflow-y: auto;
  // -webkit-transition: all 0.5s ease;
  // -moz-transition: all 0.5s ease;
  // -o-transition: all 0.5s ease;
  // transition: all 0.5s ease;
  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
